var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dishError)?_c('div',[_vm._v(" "+_vm._s(_vm.dishError)+" ")]):_c('div',[_c('div',{staticClass:"container"},[_c('PageIntro',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.dish.name)+" ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("components.menus.addNewDishDescription"))+" ")]},proxy:true}])})],1),_c('div',{staticClass:"sm:container"},[_c('DishForm',{attrs:{"current-dish":_vm.dish},scopedSlots:_vm._u([{key:"dishFormBottom",fn:function(){return [_c('button',{class:[
              'focus:outline-none group relative inline-flex h-10 w-full cursor-pointer items-center justify-center rounded-sm py-2 px-5 text-sm font-medium text-white transition-all duration-300 hover:bg-primary-700',
              { 'bg-primary-600 shadow-inner': _vm.isFormValid },
              { 'pointer-events-none bg-neutral-200': !_vm.isFormValid } ],attrs:{"disabled":!_vm.isFormValid},on:{"click":_vm.editDish}},[_c('span',{class:[
                'absolute top-0 left-0 bottom-0 w-0 bg-primary-400 transition-all duration-500',
                { 'w-full opacity-0': _vm.editSuccessMessage } ]}),_c('span',{staticClass:"relative"},[_vm._v(" "+_vm._s(_vm.$t("global.saveChanges"))+" ")])]),_c('BaseButton',{attrs:{"size":"medium","variant":"outline","full-width":"","text":_vm.$t("components.menus.viewDish")},on:{"buttonClick":_vm.viewDish}}),_c('BaseButton',{attrs:{"size":"medium","variant":"outline","full-width":"","text":_vm.$t("global.back")},on:{"buttonClick":_vm.redirectToPreviousPage}}),_c('div',{class:[
              'rounded-sm border-success-500 bg-success-50 px-4 py-2 text-center text-xs font-medium text-success-600 opacity-0 transition-all duration-500',
              { 'opacity-100': _vm.editSuccessMessage } ]},[_vm._v(" "+_vm._s(_vm.$t("components.menus.dishSaved"))+" ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }