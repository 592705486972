<template>
  <div>
    <div v-if="dishError">
      {{ dishError }}
    </div>
    <div v-else>
      <div class="container">
        <PageIntro>
          <template v-slot:title>
            {{ dish.name }}
          </template>
          <template v-slot:description>
            {{ $t("components.menus.addNewDishDescription") }}
          </template>
        </PageIntro>
      </div>
      <div class="sm:container">
        <DishForm :current-dish="dish">
          <template v-slot:dishFormBottom>
            <button
              :class="[
                'focus:outline-none group relative inline-flex h-10 w-full cursor-pointer items-center justify-center rounded-sm py-2 px-5 text-sm font-medium text-white transition-all duration-300 hover:bg-primary-700',
                { 'bg-primary-600 shadow-inner': isFormValid },
                { 'pointer-events-none bg-neutral-200': !isFormValid },
              ]"
              :disabled="!isFormValid"
              @click="editDish"
            >
              <span
                :class="[
                  'absolute top-0 left-0 bottom-0 w-0 bg-primary-400 transition-all duration-500',
                  { 'w-full opacity-0': editSuccessMessage },
                ]"
              ></span>
              <span class="relative">
                {{ $t("global.saveChanges") }}
              </span>
            </button>
            <BaseButton
              size="medium"
              variant="outline"
              full-width
              :text="$t(`components.menus.viewDish`)"
              @buttonClick="viewDish"
            >
            </BaseButton>
            <BaseButton
              size="medium"
              variant="outline"
              full-width
              :text="$t(`global.back`)"
              @buttonClick="redirectToPreviousPage"
            >
            </BaseButton>
            <div
              :class="[
                'rounded-sm border-success-500 bg-success-50 px-4 py-2 text-center text-xs font-medium text-success-600 opacity-0 transition-all duration-500',
                { 'opacity-100': editSuccessMessage },
              ]"
            >
              {{ $t("components.menus.dishSaved") }}
            </div>
          </template>
        </DishForm>
      </div>
    </div>
  </div>
</template>

<script>
import PageIntro from "@/components/menus/PageIntro";
import DishForm from "@/components/menus/DishForm";

import { mapActions, mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    PageIntro,
    DishForm,
  },

  beforeRouteEnter(to, _from, next) {
    Promise.all([
      store.dispatch("dish/fetchDish", { dishUuid: to.params.dishUuid }),
      store.dispatch("dish/fetchDishCourses"),
      store.dispatch("dishForm/resetForm"),
    ]).finally(() => {
      next();
    });
  },

  computed: {
    ...mapGetters("dishForm", ["isFormValid", "editSuccessMessage"]),
    ...mapGetters("dish", ["dish", "dishError"]),
  },

  created() {
    this.updateDishUuid(this.dish.uuid);
  },

  methods: {
    ...mapActions("dishForm", ["editDish", "updateDishUuid"]),

    redirectToPreviousPage() {
      this.$router.go(-1);
    },

    viewDish() {
      this.$router.push({
        name: "dish-detail",
        params: { dishUuid: this.dish.uuid },
      });
    },
  },
};
</script>
